import React from 'react';
import PropTypes from 'prop-types';
import { client as apollo } from 'cccisd-apollo';
import { ActivationForm, LoginForm } from 'cccisd-laravel-nexus';
import { addEvent } from 'cccisd-laravel-eventlog';
import Loader from 'cccisd-loader';
import axios from 'cccisd-axios';

import style from './style.css';
import activationQuery from './teacherPawn.graphql';

const Boilerplate = window.cccisd.boilerplate;
const Fortress = window.cccisd.fortress;

export default class AcceptCoaching extends React.Component {
    static propTypes = {
        match: PropTypes.object,
    };

    state = {
        loading: true,
    };

    // get pawn id from URL
    componentDidMount = async () => {
        const requesteeHash = this.props.match.params.teacherHash;

        if (Fortress.auth() && Fortress.user.acting.random_hash !== requesteeHash) {
            await this.logOut();
            window.location.reload();
        } else {
            await this.getData();
        }
    };

    logOut = async () => {
        await axios.get(Boilerplate.route('api.nexus.logout') + '?redirect=false');
    };

    getStateObj = res => {
        const pendingRequest = res.data.roles.pendingRequest;
        const stateObj = {};
        const requestorIsAdmin = pendingRequest.requestor.pawn.role === 'admin';

        const userActivated = pendingRequest.requesteePawn.user.activatedAt !== null;
        const logedIn = Fortress.auth();

        stateObj.teacher = pendingRequest.requesteePawn;
        stateObj.coach = requestorIsAdmin ? null : pendingRequest.requestor;
        stateObj.admin = requestorIsAdmin ? pendingRequest.requestor : null;
        stateObj.activated = userActivated;
        stateObj.loggedIn = logedIn;

        return stateObj;
    };

    getData = async () => {
        const teacherHash = this.props.match.params.teacherHash;
        const coachHash = this.props.match.params.coachHash;
        const res = await apollo.query({
            query: activationQuery,
            fetchPolicy: 'network-only',
            variables: { pawnHash: teacherHash, coachHash },
        });
        if (res.data) {
            const pendingRequest = res.data.roles.pendingRequest;
            const pawnExists = pendingRequest.requesteePawn.pawn.pawnId !== null;

            if (pawnExists) {
                const stateObj = this.getStateObj(res);

                this.setState({
                    ...stateObj,
                    loading: false,
                });
            } else {
                this.setState({
                    showMessage: true,
                    loading: false,
                });
            }
        }
    };

    onActivationSuccess = async () => {
        const { teacher } = this.state;

        const info = this.getInfo();

        const res = await axios.post(Boilerplate.route('teacher.accept.invite'), {
            requestingPawnId: info.requestor.pawn.pawnId,
        });

        if (res.data.status === 'success') {
            const metadata = info.isCoach
                ? {
                      teacher: {
                          name: teacher.user.fullName,
                          pawnId: teacher.pawn.pawnId,
                          isCoach: teacher.pawn.role === 'coach',
                      },
                      coach: {
                          name: info.requestor.user.fullName,
                          pawnId: info.requestor.pawn.pawnId,
                      },
                  }
                : {
                      admin: {
                          name: info.requestor.user.fullName,
                          pawnId: info.requestor.pawn.pawnId,
                      },
                      teacher: {
                          name: teacher.user.fullName,
                          pawnId: teacher.pawn.pawnId,
                          isCoach: teacher.pawn.role === 'coach',
                      },
                  };
            addEvent(
                {
                    eventType: info.isCoach ? 'linkCoach' : 'linkAdmin',
                    targetId: teacher.pawn.pawnId,
                    targetType: 'pawn',
                    metadata: JSON.stringify(metadata),
                },
                { saveImmediately: true }
            );
        }
        return res.data.status === 'success';
    };

    renderActivation = () => {
        const info = this.getInfo();

        const label = info.isCoach ? 'coached' : 'administered';
        return (
            <>
                <div className="alert alert-info" style={{ textAlign: 'center' }}>
                    You have been invited to be {label} by:{' '}
                    {info.requestor.user.fullNameWithUsername}. Please activate your account to
                    complete your acceptance.
                </div>
                <div className={style.activationForm}>
                    <ActivationForm
                        alsoLogin
                        activationToken={this.state.teacher.user.activationToken}
                        onSuccess={this.onActivationSuccess}
                    />
                </div>
            </>
        );
    };

    getInfo = () => {
        const { coach, admin } = this.state;

        const requestor = coach ? coach : admin;

        const isCoach = requestor.pawn.role === 'coach';

        return { isCoach, requestor };
    };

    showSuccess = async () => {
        await this.onActivationSuccess();
    };

    renderSuccess = () => {
        if (this.state.loggedIn) {
            const info = this.getInfo();
            const label = info.isCoach ? 'coaching' : 'administration';
            this.showSuccess();
            return (
                <div className="alert alert-success" style={{ textAlign: 'center' }}>
                    You have accepted {label} from: {info.requestor.user.fullNameWithUsername}
                </div>
            );
        }

        if (!this.state.loggedIn) {
            const info = this.getInfo();

            const label = info.isCoach ? 'coached' : 'administered';
            return (
                <>
                    <div className="alert alert-info" style={{ textAlign: 'center' }}>
                        You have been invited to be {label} by:{' '}
                        {info.requestor.user.fullNameWithUsername}. Please login to complete your
                        acceptance.
                    </div>
                    <div className={style.login}>
                        <LoginForm onSuccess={this.onActivationSuccess} />
                    </div>
                </>
            );
        }
    };

    renderMessage = () => {
        return (
            <div className="alert alert-danger" style={{ textAlign: 'center' }}>
                There is no data for this invitation. Please contact your system administrator for
                assistance.
            </div>
        );
    };

    render() {
        if (this.state.loading) {
            return <Loader loading />;
        }

        const showActivation = !this.state.activated && !this.state.loggedIn;
        const showMessage = this.state.showMessage;

        return (
            <div>
                {showMessage ? (
                    this.renderMessage()
                ) : (
                    <>{showActivation ? this.renderActivation() : this.renderSuccess()}</>
                )}
            </div>
        );
    }
}
