import React from 'react';
import PropTypes from 'prop-types';
import axios from 'cccisd-axios';
import { client as apollo } from 'cccisd-apollo';
import { NotFound } from 'cccisd-laravel-boilerplate';
import Loader from 'cccisd-loader';
import { DeploymentPlayer } from 'cccisd-laravel-assignment';
import practiceQuery from './practiceQuery.graphql';
import _get from 'lodash/get';

const Fortress = window.cccisd && window.cccisd.fortress;
const Boilerplate = window.cccisd && window.cccisd.boilerplate;

export default class ObservationalPractice extends React.Component {
    static propTypes = {
        /** The layout to use for the 404 page */
        errorLayout: PropTypes.func,
        /* deployment hash */
        deploymentHash: PropTypes.string,
        respondentHash: PropTypes.string,
        // PD  update
        match: PropTypes.object,
    };

    static defaultProps = {
        errorLayout: null,
    };

    state = {
        isLoading: true,
        show404: false,
    };

    componentDidMount() {
        this.evaluateDeployment();
    }

    /**
     * Get the deployment handle and pawn data, which is needed to route
     * to the deployment for this pawn.
     */
    evaluateDeployment = async () => {
        const { match } = this.props;
        const deploymentHandle = match.params.deploymentHandle;

        if (!deploymentHandle) {
            return this.setState({ show404: true, isLoading: false });
        }

        const isPD = match.path.includes('/PD');

        const roleHandle = _get(Fortress, 'user.acting.role.handle');
        const newDeploymentHandle = isPD ? deploymentHandle : deploymentHandle + roleHandle;
        try {
            const result = await apollo.query({
                query: practiceQuery,
                fetchPolicy: 'network-only',
                variables: {
                    deploymentHandle: newDeploymentHandle,
                },
            });

            if (!result.data.flows.deployment) {
                return this.setState({ show404: true, isLoading: false });
            }

            const deployment = _get(result, 'data.flows.deployment', {});

            if (isPD) {
                this.setState({ deploymentId: deployment.deploymentId });
            } else {
                await this.repeatedDeployment(deployment);
                this.setState({ isLoading: true });
            }
        } catch (error) {
            this.setState({ show404: true });
        }

        this.setState({ isLoading: false });
    };

    /**
     * All observation practices deployments are 'repeated'.  User can take any of the
     * practice as many times and each time is a new 'child' deployment id.  In these cases,
     * we need to call the assignment repeated route to determine the next available
     * child deployment id. If this is a resume, the route will return the deployment
     * id to continue.
     *
     * Users must be logged in to access the deployments.
     *
     * @param {object} deployment
     */
    repeatedDeployment = async deployment => {
        let response = await axios.get(
            Boilerplate.route('api.assignmentDeployment.repeated', {
                deploymentId: deployment.deploymentId,
                pawnId: Fortress.user.acting.id,
                hash: Fortress.user.acting.respondent_hash,
            })
        );

        const responseDeploymentId = _get(response, 'data.data.deploymentId');

        if (responseDeploymentId) {
            this.setState({
                deploymentId: responseDeploymentId,
            });
        }
    };

    render() {
        const { isLoading, show404, deploymentId } = this.state;
        const { errorLayout, match } = this.props;
        const roleHandle = _get(Fortress, 'user.acting.role.handle');
        const deploymentHandle = match.params.deploymentHandle;
        const isPD = match.path.includes('/PD');
        const showWarningMessage = isPD && roleHandle !== 'coach' && deploymentHandle.includes('motivationalPD');

        if (isLoading) {
            return <Loader loading />;
        }

        if (showWarningMessage) {
            return (
                <div className="alert alert-warning" style={{ textAlign: 'center' }}>
                    This survey is only available to Coaches.
                </div>
            );
        }
        if (show404) {
            if (errorLayout) {
                let ErrorLayout = errorLayout;
                return (
                    <ErrorLayout>
                        <NotFound />
                    </ErrorLayout>
                );
            }
            return <NotFound />;
        }

        return (
            <div style={{ marginTop: '20px' }}>
                {' '}
                <DeploymentPlayer
                    deploymentId={deploymentId}
                    pawnId={Fortress.user.acting.id}
                    pawnHash={Fortress.user.acting.random_hash}
                    flowProps={{
                        hidePlayButton: true,
                        isPrintMode: false,
                    }}
                />
            </div>
        );
    }
}
